/* You can add global styles to this file, and also import other style files */

$common-font: "Times New Roman", Times, serif;
$heading-font: "Times New Roman", Times, serif;
$transition: .5s all ease;
$all-size: 20px;
$white-color: #ffffff;
$gold-color: #d5c07e;
$black-color: #000000; // #333333;
$footer-font: 16px;
$textColor: #c8c6c6;

/*-- Default CSS --*/
body {
    font-family: $common-font;
    color: $black-color;
    background-color: $white-color;
    font-size: $all-size;
    --yellow: #d5c07e;
}
h1, h2, h3, h4, h5, h6 {
    color: $black-color;
    line-height: 1.4;

}
p {
    line-height: 1.8;
    text-align: justify !important;

}
a {
    transition: $transition;
    text-decoration: none;
    
    &:hover {
        text-decoration: none;
    }
}
img {
    max-width: 100%;
}
.d-table {
    width:100%;
    height:100%;
}
.d-table-cell {
    vertical-align: middle;
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pb-100 {
    padding-bottom: 100px;
}
button {
    &:focus{
        outline: 0;
    }
}
.btn.focus, .btn:focus {
    box-shadow: none;
}
/*-- End Default CSS --*/

/*----- Home Page 1 -----*/
/*-- Navbar --*/
.main-nav {
    background-color: $black-color;
    transition: $transition;
    
    nav {
        .navbar-nav {
            margin-left: auto;
            margin-right: auto;

            .nav-item {
                .dropdown-menu {
                    background: #242323;

                    li {
                        a{
                            &:hover, &:focus, &.active {
                                color: $gold-color;
                                letter-spacing: 1px;

                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    a {
                        color: $gold-color;
                    }
                }
                a {
                    font-size: 18px;
                    text-transform: capitalize;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 7px;
                        height: 7px;
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                        bottom: -12px;
                        transform: rotate(45deg);
                        transition: $transition;
                        opacity: 0;
                        animation: a-one 5s linear infinite;
                        background-color: $gold-color;
                    }
                    &:hover, &:focus, &.active {
                        color: $gold-color;
                        
                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
@keyframes a-one {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.side-nav {
    a {
        display: inline-block;
        font-size: 16px;
        color: $white-color;
        border: 1px solid $gold-color;
        padding: 12px 15px;
        position: relative;
        z-index: 1;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            opacity: 0;
            z-index: -1;
            transition: $transition;
            background-color: $gold-color;
        }
        &:after {
            position: absolute;
            content: '';
            right: 0;
            top: 0;
            width: 0;
            height: 100%;
            opacity: 0;
            z-index: -1;
            transition: $transition;
            background-color: $gold-color;
        }
        &:hover {
            &:before {
                width: 50%;
                opacity: 1;
            }
            &:after {
                width: 50%;
                opacity: 1;
            }
        }
    }
}
.menu-shrink {
    padding-top: 8px;
    padding-bottom: 8px;
}
/*-- End Navbar --*/

/*-- Banner --*/
.banner-img-one {
    background-image: url('assets/img/home-one/home-bg1.jpg');
}
.banner-area {
    height: 830px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.banner-item {
    .banner-left {
        margin-top: 60px;

        h1 {
            color: $white-color;
            margin-bottom: 30px;
            font: {
                size: 45px;
                family: $heading-font;
                weight: 900;
            }
        }
        p {
            margin-bottom: 40px;
            color: #e0e0e0;
            font-size: 16px;
        }
        a {
            display: inline-block;
            color: $white-color;
            border: 3px solid $gold-color;
            padding: 18px 25px;

            i {
                display: inline-block;
                font-size: 20px;
                position: relative;
                top: 1px;
                left: 5px;
                transition: $transition;
            }
            &:hover {
                background-color: $gold-color;

                i {
                    left: 0;
                }
            }
        }
    }
    .banner-right {
        img {
            width: 85%;
            position: relative;
            bottom: -180px;
        }
        .banner-animation {
            animation: banner-animation 10s infinite linear;
        }
    }
}
@keyframes banner-animation {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, -50px);
    }
    100% {
        transform: translate(0, 0);
    }
}
.banner-bottom {
    border-top: 30px solid #b69d7459;
    border-bottom: 30px solid #b69d7459;
    border-left: 40px solid #b69d7459;
    border-right: 40px solid #b69d7459;
    display: inline-block;
    position: absolute;
    bottom: -65px;
    margin-left: auto;

    ul {
        margin: 0;
        padding: 0;
        margin-bottom: -2px;

        li {
            list-style-type: none;
            display: inline-block;
            background-color: $white-color;
            position: relative;

            i {
                padding-left: 20px;
                padding-right: 20px;
                line-height: 100px;
                text-align: center;
                font-size: 50px;
                display: inline-block;
                background-color: $gold-color;
            }
            &:last-child {
                padding-left: 30px;
                padding-right: 110px;
                padding-top: 23px;
                padding-bottom: 24px;
                margin-left: -4px;

                span {
                    font-size: 14px;
                    margin-bottom: 8px;
                    color: $gold-color;
                }
                p {
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 18px;
                    color: $gold-color;
                }
                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
}
/*-- End Banner --*/

/*-- Help --*/
.help-area {
    padding-top: 165px;
    position: relative;
}
.help-shape {
    img {
        position: absolute;
        right: 50px;
        height: 462px;
        bottom: 0;
        animation: a-two 10s infinite linear; 
    }
}
@keyframes a-two {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-50px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
.help-left {
    position: relative;
    max-width: 610px;
    margin-left: auto;
    padding-top: 65px;
    padding-bottom: 65px;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: -60px;
        top: 0;
        z-index: -1;
        animation: 10s help-animation linear infinite;
        border: 25px solid $gold-color;
        background-color: transparent;
    }
}
@keyframes help-animation {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(30px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
@keyframes help-animation-two {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(10px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
.help-item {
    margin-bottom: 30px;

    .help-right {
        max-width: 520px;
        margin-left: 50px;
        h2 {
            font: {
                size: 38px;
                weight: 700;
                family: $heading-font;
            }
            margin-bottom: 25px;
            line-height: 1.4;
        }
        p {
            color: $black-color;
            margin-bottom: 25px;
        }
        .help-inner-left {
            width: 49%;
            display: inline-block;

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    font-size: 18px;
                    font-weight: 600;
                    color: #111111;
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    i {
                        color: $gold-color;
                        margin-right: 10px;
                        font-size: 25px;
                        position: relative;
                        top: 2px;
                    }
                }
            }
        }
        .help-inner-right {
            width: 50%;
            display: inline-block;

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    font-size: 18px;
                    font-weight: 600;
                    color: #111111;
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    i {
                        color: $gold-color;
                        margin-right: 10px;
                        font-size: 25px;
                        position: relative;
                        top: 2px;
                    }
                }
            }
        }
        .help-signature {
            img {
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }
}
.cmn-btn {
    display: inline-block;
    color: $white-color;
    background-color: $gold-color;
    font-weight: 600;
    font-size: 16px;
    padding: 14px 30px;
    position: relative;
    z-index: 1;

    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        opacity: 0;
        z-index: -1;
        transition: $transition;
        background-color: $black-color;
    }
    &:after {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        opacity: 0;
        z-index: -1;
        transition: $transition;
        background-color: $black-color;
    }
    &:hover {
        color: $white-color;

        &:before {
            width: 50%;
            opacity: 1;
        }
        &:after {
            width: 50%;
            opacity: 1;
        }
    }
}
/*-- End Help --*/

/*-- Practice --*/
.section-title {
    text-align: center;
    margin-bottom: 50px;
    margin-top: -7px;

    span {
        display: block;
        color: $gold-color;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px;
    }
    h2 {
        font: {
            weight: 700;
            size: 38px;
            family: $heading-font;
        }
        margin-bottom: 0;
    }
}
.practice-area {
    background-color: #f5f1f1;
}
.practice-item {
    text-align: center;
    margin-bottom: 30px;
    padding: 45px 35px;
    background-color: $black-color;
    position: relative;
    overflow: hidden;
    color: $white-color;
    transition: $transition;

    &:hover {
        background-color: $gold-color;
        transform: translate(0, -10px);

        .practice-icon {
            &:before {
                background-color: #66573e;
                transform: rotate(135deg);
            }
            &:after {
                background-color: #86714d;
                transform: rotate(-45deg);
            }
            i {
                color: $white-color;
            }
        }
    }
    .practice-icon {
        position: relative;
        z-index: 1;
        margin-top: 25px;

        &:before {
            position: absolute;
            content: '';
            width: 55px;
            height: 55px;
            left: 0;
            top: -5px;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            transform: rotate(45deg);
            z-index: -1;
            transition: $transition;
            background-color: #4c4b4b;
        }
        &:after {
            position: absolute;
            content: '';
            width: 55px;
            height: 55px;
            left: 0;
            top: -15px;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            transform: rotate(45deg);
            z-index: -2;
            transition: $transition;
            background-color: #2d2c2c;
        }
        i {
            color: $gold-color;
            margin-bottom: 32px;
            display: block;
            font-size: 38px;
            position: relative;
            top: -10px;
            transition: $transition;
        }
    }
    h3 {
        margin-bottom: 25px;
        color: $white-color;
        font: {
            family: $heading-font;
            weight: 600;
            size: 28px;
        }
    }
    p {
        margin-bottom: 25px;
    }
    a {
        display: inline-block;
        color: $white-color;
        border: 1px solid $white-color;
        padding: 15px 30px;
        position: relative;
        z-index: 1;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            opacity: 0;
            z-index: -1;
            transition: $transition;
            background-color: $white-color;
        }
        &:after {
            position: absolute;
            content: '';
            right: 0;
            top: 0;
            width: 0;
            height: 100%;
            opacity: 0;
            z-index: -1;
            transition: $transition;
            background-color: $white-color;
        }
        &:hover {
            color: $black-color;

            &:before {
                width: 50%;
                opacity: 1;
            }
            &:after {
                width: 50%;
                opacity: 1;
            }
        }
    }
    .practice-shape-one {
        position: absolute;
        right: -25px;
        bottom: -60px;
    }
    .practice-shape-two {
        position: absolute;
        right: -72px;
        bottom: -56px;
    }
}
/*-- End Practice --*/

/*-- Expertise --*/
.expertise-area {
    background-color: $black-color;    

    .section-title {
        h2 {
            margin-left: 0;
            color: $white-color;
        }
    }
}
.expertise-item {
    color: $white-color;

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            position: relative;
            margin-bottom: 70px;

            &:last-child {
                margin-bottom: 0;
            }
            .expertise-icon {
                position: absolute;
                top: 0;
                left: 0;

                i {
                    color: $gold-color;
                    font-size: 45px;
                    position: absolute;
                    left: 22px;
                    top: 22px;
                }
            }
            h3 {
                padding-left: 135px;
                font: {
                    size: 28px;
                    weight: 500;
                    family: $heading-font;
                }
                margin-bottom: 18px;
                color: $white-color;
            }
            p {
                padding-left: 135px;
                font-size: 16px;
                margin-bottom: 0;
            }
        }
    }
}
.expertise-image {
    text-align: center;
    position: relative;
    z-index: 1;
    padding-top: 100px;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        top: 0;
        z-index: -1;
        border: 10px solid $gold-color;
        background-color: #373535;
    }
    img {
        position: relative;
        bottom: 10px;
    }
}
/*-- End Expertise --*/

/*-- Portfolio --*/
.portfolio-area {
    .text-center {
        margin-top: 20px;
    }
}
.portfolio-item {
    width: 100%;
    transition: $transition;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    color: $white-color;
    background: {
        size: cover;
        position: center center;
        repeat: no-repeat;
    };
    img {
        width: 100%;
    }
    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0,0,0,0.977081213104591) 0%, rgba(182,157,116,0.28606997714953475) 56%);
    }
    &:hover {
        transform: translate(0, -10px);

        h3 {
            a {
                color: $gold-color;
            }
        }
    }
    .portfolio-inner {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 30px;

        span {
            display: block;
            margin-bottom: 20px;
            padding-bottom: 7px;
            position: relative;
    
            &:before {
                position: absolute;
                content: '';
                width: 60px;
                height: 1px;
                left: 0;
                bottom: 0;
                background-color: $gold-color;
            }
        }
        h3 {
            margin-bottom: 0;
            line-height: 1.4;
    
            a {
                display: block;
                color: $white-color;
                font-weight: 600;
                font-size: 22px;
                margin-bottom: 5px;
    
                &:hover {
                    color: $gold-color;
                }
            }
        }
        p {
            margin-bottom: 0;
        }
    }
}
/*-- End Portfolio --*/

/*-- Team --*/
.team-area {
    padding-bottom: 190px;
}
.team-item {
    margin-bottom: 30px;

    img {
        width: 100%;
    }
    .team-inner {
        background-color: $white-color;
        text-align: center;
        box-shadow: 0px 0px 15px 0px #ddd;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;
        margin-top: -40px;
        position: relative;
        transition: $transition;
    
        ul {
            margin: 0;
            padding: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #282828;
            max-width: 155px;
            margin-right: auto;
            margin-left: auto;
            border-radius: 30px;
            position: absolute;
            top: -25px;
            left: 0;
            right: 0;
            box-shadow: 0px 8px 13px 0px #ddd;
            transition: $transition;
    
            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 4px;
                margin-left: 4px;
    
                a {
                    display: block;
                    color: $gold-color;
                }
            }
        }
        h3 {
            margin-bottom: 4px;
            padding-top: 25px;
            transition: $transition;
            font-family: $heading-font;

            a {
                color: $black-color;
                font-weight: 600;
                font-size: 18px;
            }
        }
        span {
            display: block;
            color: $gold-color;
            font-size: 14px;
            transition: $transition;
        }
    }
    
    &:hover {
        .team-inner {
            background-color: $gold-color;
            max-width: 100%;

            ul {
                background-color: $white-color;
                box-shadow: none;

                li {
                    a {
                        &:hover {
                            color: $black-color;
                        }
                    }
                }
            }
            h3 {
                a {
                    color: $white-color;

                    &:hover {
                        color: $black-color;
                    }
                }
            }
            span {
                color: $black-color;
            }
        }
    }
}
/*-- End Team --*/

/*-- Location --*/
.loaction-area {
    position: relative;
    top: -100px;
}
.location-bg {
    // background-image: url('assets/img/home-one/location-bg.jpg');
    background-color: $black-color;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;    
    position: relative;
    padding-top: 50px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $gold-color;
        opacity: .90;
    }
    &:after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: -20px;
        width: 88%;
        height: 20px;
        margin-left: auto;
        margin-right: auto;
        background-color: #c3b59f;
    }
}
.location-item {
    margin-bottom: 30px;
    position: relative;
    padding-left: 90px;
    color: $white-color;

    .location-icon {
        position: absolute;
        left: 0;
        top: 0;

        i {
            display: inline-block;
            color: $white-color;
            font-size: 26px;
            position: absolute;
            left: 32px;
            top: 22px;
        }
    }
    h3 {
        color: $white-color;
        margin-bottom: 15px;
        font: {
            family: $heading-font;
            weight: 600;
            size: 28px;
        };
    }
    ul {
        margin: 0;
        padding: 0;
        margin-bottom: 2px;

        li {
            list-style-type: none;
            display: block;
            margin-bottom: 3px;
            font-size: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $white-color;

                &:hover {
                    color: $black-color;
                }
            }
        }
    }
    .location-link {
        display: block;
        color: #282828;
        margin-top: 10px;

        &:hover {
            color: $white-color;
        }
    }
}
/*-- End Location --*/

/*-- Contact Form --*/
.contact-form {
    background-image: url('assets/img/contact2.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.contact-wrap {
    max-width: 970px;
    margin-left: auto;
    margin-right: auto;

    .form-group {
        margin-bottom: 30px;

        .current {
            color: #ababab;
        }
        .nice-select{
            float: unset;
            
            &.open {
                .list {
                    background-color: transparent !important;
                    border: 1px solid $gold-color;
                }
            }
            .option {
                &:hover, &.focus, &.selected.focus {
                    background-color: $gold-color;
                }
            }
        }
        .form-control {
            background-color: transparent;
            border: 1px solid $gold-color;
            border-radius: 0;
            height: 60px;
            padding-left: 30px;
            color: $white-color;
            font-size: 15px;

            &:focus {
                box-shadow: none;
                border: 1px solid $white-color;
            }
        }
        ::placeholder {
            color: #ababab;
        }
        textarea {
            height: auto !important;
            padding-top: 15px;
        }
    }
}
.contact-btn {
    font-weight: 600;
    font-size: $all-size;
    color: $white-color;
    background-color: $gold-color;
    padding: 16px 40px;
    border: 0;
    position: relative;
    z-index: 1;
    transition: $transition;

    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        opacity: 0;
        z-index: -1;
        transition: $transition;
        background-color: $black-color;
        cursor: pointer;
    }
    &:after {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        opacity: 0;
        z-index: -1;
        transition: $transition;
        background-color: $black-color;
    }
    &:hover {
        color: $white-color;

        &:before {
            width: 50%;
            opacity: 1;
        }
        &:after {
            width: 50%;
            opacity: 1;
        }
    }
}
.list-unstyled {
    color: #dc3545;
    margin-bottom: 0;
    font-size: 15px;
    margin-top: 5px;
}
.text-danger {
    color: #dc3545;
    margin-top: 25px;
    margin-bottom: 0;
    font-size: 24px;
}
.text-success {
    color: #28a745;
    margin-top: 25px;
    font-size: 25px;
}
/*-- End Contact Form --*/

/*-- Blog --*/
.blog-area {
    padding-bottom: 50px;

    .owl-theme {
        .owl-dots {
            .owl-dot {
                &.active span {
                    background: $gold-color;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        left: -5px;
                        top: -5px;
                        border: 1px solid $gold-color;
                    }
                }
                span {
                    background: #daceb9;
                }
            }
        }
    }
}
.blog-item {
    border-radius: 0 0 8px 8px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 12px 0px #ddddddad;
    transition: $transition;
    margin-left: 15px;
    margin-right: 15px;

    &:hover {
        transform: translate(0, -10px);
    }
    a {
        display: block;

        img {
            width: 100%;
            border-radius: 8px 8px 0 0;
        }
    }
    .blog-inner {
        padding: 0 15px 30px 20px;

        span {
            display: block;
            text-align: center;
            font-size: 14px;
            padding-top: 10px;
            padding-bottom: 10px;
            max-width: 255px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            margin-top: -20px;
            border-radius: 30px;
            background-color: $gold-color;
            margin-bottom: 30px;
        }
        h3 {
            margin-bottom: 18px;
            line-height: 1.4;

            a {
                display: block;
                font-weight: 600;
                font-size: 20px;
                color: #111111;
                
                &:hover {
                    color: $gold-color;
                }
            }
        }
        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 15px;

            li {
                list-style-type: none;
                display: inline-block;
                color: $gold-color;
                font-size: 14px;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;

                    i {
                        font-size: 14px;
                        position: relative;
                        top: -2px;
                    }
                }
                i {
                    display: inline-block;
                    font-size: 17px;
                    margin-right: 3px;
                }
                a {
                    display: inline-block;
                    color: $gold-color;
                    font-size: 14px;

                    &:hover {
                        color: $black-color;
                    }
                }
            }
        }
        p {
            margin-bottom: 15px;
            color: #646464;
        }
        .blog-link {
            display: block;
            color: $gold-color;
            font-weight: 600;
            font-size: 18px;

            i {
                font-size: 20px;
                position: relative;
                top: 2px;
                left: -2px;
            }
            &:hover {
                color: $black-color;
                letter-spacing: 1px;
            }
        }
    }
}
/*-- End Blog --*/

/*-- Newsletter --*/
.newsletter-area {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    background-color: #212222;
    position: relative;
    top: -100px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 60px;

    img {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 260px;
    }
    h2 {
        font: {
            weight: 700;
            size: 32px;
            family: $heading-font;
        }
        color: $white-color;
        margin-bottom: 30px;
    }
    .newsletter-form {
        position: relative;
        max-width: 490px;

        .form-control {
            height: 46px;
            border-radius: 0;
            border: 1px solid $gold-color;
            background-color: transparent;
            padding-left: 20px;
            color: $white-color;
            font-size: 15px;

            &:focus {
                box-shadow: none;
            }
        }
        .validation-danger {
            color: #dc3545;
            margin-top: 10px;
        }
        ::placeholder {
            color: #a9a3a3;
        }
        .contact-btn {
            position: absolute;
            top: 0;
            right: 0;
            color: $white-color;
            border-radius: 0;
            padding: 11px 20px;
        }
    }
}
.btn.disabled, .btn:disabled {
    opacity: 1;
}
/*-- End Newsletter --*/

/*-- Footer --*/
footer {
    position: relative;
    color: $white-color;
    z-index: 1;
    background: {
        image: url('assets/img/home-one/footer.jpg');
        size: cover;
        position: center center;
        repeat: no-repeat;
    };
    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        z-index: -1;
        top: 0;
        background-color: $black-color;
        opacity: .99;
    }
    .footer-item {
        margin-bottom: 30px;

        .footer-logo {
            a {
                display: block;

                img {
                    margin-bottom: 35px;
                }
            }
            p {
                margin-bottom: 30px;
                font-size: $footer-font;
            }
            ul {
                margin: 0;
                padding: 0;
                font-size: $footer-font;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 18px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        color: $white-color;

                        &:hover {
                            color: $gold-color;
                        }
                    }
                }
            }
        }
        .footer-service {
            h3 {
                margin-bottom: 25px;
                color: $white-color;
                font-weight: 600;
                font-size: 22px;
                display: inline-block;
                border-bottom: 2px solid #d5c07e;
            }
            ul {
                margin: 0;
                padding: 0;
                font-size: $footer-font;

                li {
                    list-style-type: none;
                    display: block;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        color: $white-color;
                        display: block;

                        &:hover {
                            letter-spacing: 1px;
                            color: $gold-color;
                        }
                        i {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .footer-find {
            h3 {
                margin-bottom: 30px;
                color: $white-color;
                font-weight: 600;
                font-size: 22px;
                display: inline-block;
                border-bottom: 2px solid $gold-color;
            }
            ul {
                margin: 0;
                padding: 0;
                font-size: $footer-font;

                li {
                    list-style-type: none;
                    display: block;
                    margin-bottom: 20px;

                    i {
                        font-size: 23px;
                        margin-right: 6px;
                        position: relative;
                        top: 2px;
                        color: $gold-color;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        display: inline-block;
                        color: $white-color;

                        &:hover {
                            color: $gold-color;
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
    }
    .copyright-area {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 70px;
        border-top: 1px solid #565655;
        position: relative;

        .copyright-item {
            span {
                display: inline-block;
                margin: 0 10px;
            }
            p {
                margin-bottom: 0;
                font-size: $footer-font;
            }
            a {
                color: $white-color;
                display: inline-block;

                &:hover {
                    color: $gold-color;
                }
            }
        }
        .copyright-right {
            text-align: right;
            font-size: $footer-font;
        }
    }
}
/*-- End Footer --*/
/*----- End Home Page 1 -----*/

/*----- Home Page 2 -----*/
/*-- Banner --*/
.banner-area-two {
    .banner-item {
        .banner-right {
            img {
                bottom: -65px;
                z-index: 2;
            }
        }
    }
}
/*-- End Banner --*/

/*-- Counter --*/
.counter-area {
    background-color: #fefaf3;
}
.counter-bg {
    box-shadow: 0px 0px 20px 0px #dddddd75;
    padding-top: 50px;
    padding-bottom: 20px;
    background-image: url('assets/img/home-two/4.png');
    background-repeat: repeat;
    background-color: $white-color;
    position: relative;
    top: -110px;
    z-index: 5;
}
.counter-item {
    margin-bottom: 30px;
    position: relative;

    i {
        color: $gold-color;
        display: inline-block;
        font-size: 50px;
        position: absolute;
        top: 0;
        left: 20px;
    }
    .counter-inner {
        padding-left: 90px;

        h3 {
            font-weight: 600;
            font-size: 36px;
            color: $gold-color;
            margin-bottom: 0;
        }
        p {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 18px;
            color: #504f4f;
        }
    }
}
/*-- End Counter --*/

/*-- Help --*/
.help-area-two {
    background-color: #fefaf3;
    padding-top: 0;
    
    .help-shape {
        img {
            right: 0;
            left: 50px;
        }
    }
    .help-left {
        &:before {
            z-index: 1;
        }
        img {
            width: 100%;
            z-index: 2;
            position: relative;
        }
    }
    .help-item {
        .help-right {
            p {
                margin-bottom: 20px;
            }
            .help-signature {
                img {
                    margin-top: 25px;
                    margin-bottom: 25px;
                    
                }
            }
            h3 {
                font-weight: 600;
                font-size: $all-size;
                color: $black-color;
                margin-bottom: 10px;
            }
            span {
                display: block;
                color: $black-color;
                font-size: 14px;
            }
        }
    }
    .help-wrap {
        .help-item {
            .help-right {
                margin-left: auto;
                margin-right: 50px;
            }
            .cmn-btn {
                margin-top: 40px;
            }
        }
        .help-left {
            margin-left: 0;

            &:before {
                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    left: -60px;
                    top: 0;
                    z-index: -1;
                    animation: 10s help-animation-two linear infinite;
                    border: 25px solid $gold-color;
                    background-color: transparent;
                }
            }
        }
    }
}
/*-- End Help --*/

/*-- Practice --*/
.practice-area-two {
    background-color: $white-color;
}
/*-- End Practice --*/

/*-- Expertise --*/
.expertise-area-two {
    .expertise-item {
        ul {
            li {
                border: 2px solid $gold-color;
                padding: 20px 20px 25px 35px;
                margin-bottom: 30px;

                .expertise-icon {
                    top: 35px;
                    left: 45px;
                }
            }
        }
    }
}
/*-- End Expertise --*/
/*----- End Home Page 2 -----*/

/*----- Home Page 3 -----*/
/*-- Home Slider --*/
.slider-bg-one {
    background-image: url('assets/img/home-two/home-slider/bg1.jpg');
}
.slider-item {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 835px;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $black-color;
        opacity: .95;
    }
}
.slider-text  {
    position: relative;

    h1 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            family: $heading-font;
            weight: 900;
            size: 46px;
        }
        display: inline-block;
        margin-bottom: 25px;
    }
    p {
        margin-bottom: 37px;
        font: {
            weight: 900;
            size: 24px;
            family: $heading-font;
        }
    }
    a {
        display: inline-block;
        color: $white-color;
        border: 3px solid $gold-color;
        padding: 18px 25px;

        i {
            display: inline-block;
            font-size: 20px;
            position: relative;
            top: 1px;
            left: 5px;
            transition: $transition;
        }
        &:hover {
            background-color: $gold-color;

            i {
                left: 0;
            }
        }
    }
}
.slider-shape {
    .s-s-one {
        position: relative;
        top: 133px;
    }
    .s-s-two {
        position: relative;
        top: 180px;
    }
    .s-s-three {
        position: relative;
        top: 115px;
    }
}
.home-slider {
    .owl-prev {
        position: absolute;
        top: 45%;
        left: 20px;
        width: 60px;
        height: 60px;
        font-size: 30px !important;
        line-height: 60px !important;
        border-radius: 50% !important;
        color: $white-color !important;
        background-color: #b69d743b !important;
        transition: $transition;

        &:hover {
            background-color: $gold-color !important;
        }
    }
    .owl-next {
        position: absolute;
        top: 45%;
        right: 20px;
        width: 60px;
        height: 60px;
        font-size: 30px !important;
        line-height: 60px !important;
        border-radius: 50% !important;
        color: $white-color !important;
        background-color: #b69d743b !important;        
        transition: $transition;

        &:hover {
            background-color: $gold-color !important;
        }
    }
}
.home-slider.owl-theme .owl-nav {
    margin-top: 0;
}
.home-slider.owl-theme {
    .owl-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 120px;

        .owl-dot {
            &.active {
                span {
                    background: $white-color;
                    width: 30px;
                }
            }
            span {
                background: $gold-color;
                width: 20px;
                height: 5px;
                transition: $transition;
            }
        }
    }
}
/*-- End Home Slider --*/

/*-- Slider Bottom --*/
.slider-bottom-area {
    .slider-bottom-wrap {
        // background-color: #b69d7459;
        padding-top: 45px;
        padding-bottom: 15px;
        padding-left: 45px;
        padding-right: 45px;
        position: relative;
        top: -100px;
        z-index: 1;

        .banner-bottom {
            border-top: 0;
            border-bottom: 0;
            border-right: 0;
            border-left: 0;
            position: relative;
            bottom: 0;
            margin-bottom: 30px;

            ul {
                li {
                    &:last-child {
                        padding-left: 20px;
                        padding-right: 50px;
                    }
                }
            }
        }
    }
}
/*-- End Slider Bottom --*/

/*-- Help --*/
.help-area-three {
    padding-top: 0;
}
/*-- End Help --*/

/*-- Counter --*/
.counter-area-two {
    background-color: transparent;
}
/*-- End Counter --*/
/*----- End Home Page 3 -----*/

/*----- About Page -----*/
/*-- Page Title --*/
.page-title-area-three {
    .page-title-text {
        margin-top: 85px;
    }
}
.title-img-one {
    background-image: url('assets/img/about/bg-3.jpg');
}
.page-title-area {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 450px;
    text-align: center;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $black-color;
        opacity: 0.7;
    }
}
.page-title-text {
    position: relative;
    margin-top: 120px;
    color: $white-color;

    h2 {
        font: {
            weight: 900;
            size: 38px;
            family: $heading-font;
        }
        color: $white-color;
        margin-bottom: 20px;
    }
    ul {
        margin: 0;
        padding: 0;
        margin-bottom: 25px;

        li {
            list-style-type: none;
            display: inline-block;
            font-weight: 600;
            font-size: 17px;

            i {
                font-size: 20px;
                position: relative;
                top: 1px;
                display: inline-block;
            }
            a {
                display: inline-block;
                color: $white-color;

                &:hover {
                    color: $gold-color;
                }
            }
        }
    }
    .page-title-btn {
        a {
            display: inline-block;
            color: $white-color;
            border: 3px solid $gold-color;
            padding: 12px 28px;
            font-size: 15px;
    
            i {
                display: inline-block;
                font-size: 20px;
                position: relative;
                top: 1px;
                left: 5px;
                transition: $transition;
            }
            &:hover {
                background-color: $gold-color;
    
                i {
                    left: 0;
                }
            }
        }
    }
}
/*-- End Page Title --*/

/*-- Help --*/
.help-area-four {
    padding-top: 100px;
}
/*-- End Help --*/

/*-- About --*/
.about-area {
    padding-bottom: 170px;
}
.about-item {
    margin-bottom: 30px;

    .about-video-wrap {
        position: relative;

        .about-video {
            position: relative;

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: $black-color;
                opacity: .80;
            }
        }
        a {
            color: $gold-color;
            position: absolute;
            top: 46%;
            left: 46%;
            display: block;
            background-color: $white-color;
            width: 60px;
            height: 60px;
            line-height: 60px;
            border-radius: 50%;
            text-align: center;
            font-size: 20px;

            &:hover {
                color: $white-color;
                background-color: $gold-color;
            }
        }
    }
    .about-content {
        margin-top: 25px;
        margin-bottom: 25px;

        h2 {
            font: {
                weight: 600;
                size: 22px;
                family: $heading-font;
            }
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
            color: #4a4a4a;
        }
    }
    .about-information {
        margin-bottom: 25px;

        h2 {
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 15px;

            span {
                display: inline-block;
                color: $gold-color;
            }
        }
        p {
            margin-bottom: 10px;
            color: $black-color;
        }
    }
}
/*-- End About --*/
/*----- End About Page -----*/

/*----- Attorneys Page -----*/
/*-- Team --*/
.team-area-two {
    padding-bottom: 170px;
}
/*-- End Team --*/
/*----- End Attorneys Page -----*/

/*----- Attorneys Details Page -----*/
/*-- Attorneys Details --*/
.attor-details-item {
    margin-bottom: 30px;

    img {
        width: 100%;
    }
    .attor-details-left {
        padding: 0 50px 40px 50px;
        box-shadow: 0px 0px 20px 0px #ddd;
        margin-bottom: 30px;

        .attor-social {
            text-align: center;
            margin-top: -28px;

            ul {
                margin: 0;
                padding: 0;
                background-color: #282828;
                display: inline-block;
                padding: 12px 30px;
                border-radius: 10px;
                box-shadow: 0px 0px 15px 0px #ddd;
                margin-bottom: 25px;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 4px;
                    margin-left: 4px;

                    a {
                        display: block;
                        color: $gold-color;
                        font-size: 18px;

                        &:hover {
                            color: $white-color;
                        }
                    }
                }
            }
        }
        .attor-social-details {
            margin-bottom: 30px;

            h3 {
                font: {
                    weight: 600;
                    family: $heading-font;
                    size: 22px;
                }
                margin-bottom: 20px;
            }
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    color: $black-color;
                    margin-bottom: 13px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        display: inline-block;
                        color: $black-color;

                        &:hover {
                            color: $gold-color;
                        }
                    }
                    i {
                        display: inline-block;
                        color: $gold-color;
                        font-size: 18px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .attor-work {
            h3 {
                font: {
                    weight: 600;
                    family: $heading-font;
                    size: 22px;
                }
                margin-bottom: 20px;
            }
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    color: $black-color;
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .attor-work-left {
                display: inline-block;

                ul {
                    li {
                        position: relative;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 50px;
                            height: 1px;
                            top: 13px;
                            right: -88px;
                            background-color: #cccccc;
                        }
                    }
                }
            }
            .attor-work-right {
                display: inline-block;
                margin-left: 115px;
            }
        }
    }
    .attor-prev {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;

                &:last-child {
                    float: right;
                }
                a {
                    display: block;
                    color: #3f3f3f;
                    border: 1px solid $gold-color;
                    padding: 10px 20px;
                    border-radius: 8px;

                    &:hover {
                        color: $white-color;
                        background-color: $gold-color;
                    }
                }
            }
        }
    }
    .attor-details-right {
        .attor-details-name {
            margin-bottom: 40px;

            h2 {
                font: {
                    weight: 600;
                    size: 38px;
                    family: $heading-font;
                }
                margin-bottom: 15px;
            }
            span {
                display: block;
                color: #999897;
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 5px;
            }
            p {
                margin-bottom: 0;
                color: #838383;
            }
        }
        .attor-details-things {
            margin-bottom: 30px;

            h3 {
                color: $gold-color;
                font: {
                    weight: 600;
                    size: 22px;
                    family: $heading-font;
                }
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 20px;
                color: $black-color;
            }
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    color: $black-color;
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
/*-- End Attorneys Details --*/

/*-- Contact Form --*/
.contact-form-two {
    background-image: none;

    h2 {
        font: {
            size: 25px;
            weight: 600;
            family: $heading-font;
        }
        margin-bottom: 40px;
        text-align: center;
    }
    .contact-wrap {
        max-width: 100%;
        padding: 55px 100px;
        box-shadow: 0px 0px 20px 0px #ddd;

        .form-group {
            .form-control {
                &:focus {
                    border: 1px solid $black-color;
                }
            }
            .list {
                li {
                    background-color: $white-color;
                    color: $black-color;
                }
            }
        }
    }
}
/*-- End Contact Form --*/
/*----- End Attorneys Details Page -----*/

/*----- Case Studies Page -----*/
/*-- Portfolio --*/
.portfolio-area-two {
    padding-bottom: 200px;
}
.case-pagination {
    text-align: center;
    margin-top: 10px;

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 5px;
            margin-left: 5px;

            a {
                display: block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 50%;
                font-weight: 600;
                color: #111111;
                background-color: #d3d2d1;
                box-shadow: 0px 0px 15px 0px #d3d2d1;

                &:hover {
                    color: $white-color;
                    background-color: $gold-color;
                }
                i {
                    font-weight: 600;
                }
            }
        }
    }
}
/*-- End Portfolio --*/
/*----- End Case Studies Page -----*/

/*----- Case Studies Details Page -----*/
/*-- Case Details Img --*/
.case-details-item {
    margin-bottom: 30px;

    img {
        width: 100%;
    }
    h3 {
        font: {
            size: 22px;
            weight: 600;
            family: $heading-font;
        }
        margin-bottom: 20px;
        line-height: 30px;
    }
    p {
        margin-bottom: 0;
        color: #4a4a4a;
    }
    ul {
        margin: 0;
        padding: 0;
        margin-top: 20px;
        margin-bottom: 20px;

        li {
            list-style-type: none;
            display: block;
            color: #4a4a4a;

            span {
                display: inline-block;
                font-weight: 600;
            }
        }
    }
}
/*-- End Case Details Img --*/

/*-- Case Details Tab --*/
.case-details-tab {
    background-color: #1c1c1c;

    .case-details-tab-item {
        h2 {
            color: $white-color;
            font: {
                size: 38px;
                weight: 600;
                family: $heading-font;
            }
            margin-bottom: 30px;
        }
        .nav-pills {
            margin-bottom: 30px;
            
            .nav-link {
                border-radius: 0;
                color: $white-color;
                border: 1px solid $gold-color;
                width: 150px;
                text-align: center;
                margin-right: 10px;
                font-size: $all-size;
                padding: 12px 0;
            }
        }
        .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
            background-color: $gold-color;
        }
        .tab-content {
            h3 {
                line-height: 1.4;
                font: {
                    weight: 600;
                    size: 22px;
                    family: $heading-font;
                }
                margin-bottom: 15px;
                color: $white-color;
            }
            p {
                color: #c8c6c6;
                margin-bottom: 0;
            }
        }
        .case-details-tab-img {
            text-align: center;
            position: relative;
            z-index: 1;
            padding-top: 20px;
            padding-right: 10px;
            padding-left: 10px;

            img {
                position: relative;
                bottom: 10px;
            }
            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                top: 0;
                z-index: -1;
                border: 10px solid #b69d74;
                background-color: #373535;
            }
        }
    }
}
/*-- End Case Details Tab --*/

/*-- Portfolio --*/
.portfolio-area-three {
    padding-bottom: 170px;
}
/*-- End Portfolio --*/
/*----- End Case Studies Details Page -----*/

/*----- Blog Details Page -----*/
/*-- Blog Details --*/
.blog-area-two {
    .blog-item {
        margin-right: 0;
        margin-left: 0;
    }
}
.blog-details-item {
    margin-bottom: 30px;

    .blog-details-img {
        padding-bottom: 20px;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            width: 80%;
            height: 1px;
            left: 0;
            right: 0;
            bottom: 0;
            margin-left: auto;
            margin-right: auto;
            background-color: #ebebeb;
        }
        img {
            width: 100%;
            margin-bottom: 40px;
        }
        h2 {
            font: {
                weight: 600;
                size: 22px;
                family: $heading-font;
            }
            line-height: 30px;
            padding-right: 60px;
            margin-bottom: 20px;
        }
        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 15px;

            li {
                list-style-type: none;
                display: inline-block;
                font-size: 14px;
                color: #a8a7a5;
                margin-right: 12px;

                &:last-child {
                    margin-right: 0;
                }
                a {
                    display: inline-block;
                    color: #a8a7a5;

                    &:hover {
                        color: $black-color;
                    }
                }
                i {
                    color: $gold-color;
                    position: relative;
                    top: 0;
                    font-size: 17px;
                    margin-right: 4px;
                }
            }
        }
        p {
            margin-bottom: 15px;
            color: #464646;
        }
    }
    .blog-details-social {
        text-align: center;
        padding-top: 25px;
        margin-bottom: 20px;

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;

                span {
                    display: inline-block;
                    color: #4a4a4a;
                    font-weight: 500;
                    margin-right: 10px;
                }
                a {
                    display: block;
                    color: #a5a5a5;
                    font-size: 22px;
                    margin-right: 10px;
                    position: relative;
                    top: 1px;

                    &:hover {
                        color: $black-color;
                    }
                }
            }
        }
    }
    .blog-details-nav {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;

        a {
            color: #3f3f3f;
            border: 1px solid $gold-color;
            padding: 10px 22px;
            border-radius: 8px;
            display: inline-block;

            &:hover {
                color: $white-color;
                background-color: $gold-color;
            }
        }
        .nav-prev {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .nav-next {
            flex: 0 0 50%;
            max-width: 50%;
            text-align: right;
        }
    }
    .blog-details-contact {
        h2 {
            font: {
                weight: 600;
                size: 22px;
                family: $heading-font;
            }
            margin-bottom: 30px;
        }
        .contact-form {
            background-image: none;

            .form-group {
                .form-control {
                    color: $black-color;

                    &:focus {
                        border: 1px solid $black-color;
                    }
                }
                .list {
                    li {
                        color: $white-color;
                    }
                }
                .nice-select.open .list {
                    background-color: $gold-color !important;
                }
            }
        }
    }
    .blog-details-search {
        padding: 50px 55px 65px;
        box-shadow: 0px 0px 20px 0px #ddd;
        margin-bottom: 60px;

        .search-area {
            position: relative;
            margin-bottom: 30px;

            .form-control {
                height: 50px;
                border: 1px solid $gold-color;
                border-radius: 0;
                padding-left: 20px;

                &:focus {
                    box-shadow: none;
                    border: 1px solid $gold-color;
                }
            }
            .blog-details-btn {
                position: absolute;
                transition: $transition;
                color: $white-color;
                background-color: $gold-color;
                top: 0;
                right: 0;
                width: 55px;
                height: 50px;
                line-height: 50px;
                padding: 0;
                border-radius: 0;
                font-size: 19px;

                &:hover {
                    background-color: $black-color;
                }
            }
        }
        h3 {
            font: {
                weight: 600;
                size: 22px;
                family: $heading-font;
            }
            color: #464646;
            margin-bottom: 20px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                position: relative;
                padding-top: 8px;
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0;
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 8px;
                }
                .blog-details-recent {
                    padding-left: 115px;

                    h4 {
                        color: #464646;
                        font-weight: 500;
                        font-size: 14px;
                        margin-bottom: 10px;

                        a {
                            display: block;
                            color: #464646;
                        }
                    }
                }
                ul {
                    li {
                        display: inline-block;
                        color: $gold-color;
                        font-size: 14px;
                        margin-right: 10px;
                        padding-top: 0;
                        margin-bottom: 0;

                        &:last-child {
                            margin-right: 0;
                        }
                        i {
                            display: inline-block;
                            margin-right: 5px;
                        }
                        a {
                            color: $gold-color;

                            &:hover {
                                color: $black-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .blog-details-tags {
        padding: 50px 55px 45px;
        box-shadow: 0px 0px 20px 0px #ddd;

        h3 {
            font: {
                weight: 600;
                size: 22px;
                family: $heading-font;
            }
            color: #464646;
            margin-bottom: 20px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 5px;
                margin-bottom: 8px;

                a {
                    display: block;
                    color: $black-color;
                    background-color: #e5e5e5;
                    border-radius: 6px;
                    padding: 10px 25px;

                    &:hover {
                        color: $white-color;
                        background-color: $gold-color;
                    }
                }
            }
        }
    }
}
.blog-details-category {
    padding: 50px 55px;
    box-shadow: 0px 0px 20px 0px #ddd;
    margin-bottom: 60px;

    h3 {
        font: {
            weight: 600;
            size: 22px;
            family: $heading-font;
        }
        color: #464646;
        margin-bottom: 20px;
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            margin-bottom: 9px;
            padding-top: 12px;
            padding-bottom: 16px;
            border-bottom: 1px solid #d8e1ee;
            position: relative;
            padding-left: 25px;
            z-index: 1;
            transition: $transition;

            &:before {
                position: absolute;
                content: '';
                top: 2px;
                left: 0;
                width: 3px;
                height: 40px;
                background-color: $gold-color;
                transition: $transition;
                z-index: -1;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                a {
                    color: $white-color;
                }
                &:before {
                    width: 100%;
                }
                i {
                    opacity: 1;
                }
            }
            a {
                display: block;
                color: $black-color;
                font-weight: 500;
                font-size: 16px;
            }
            i {
                display: inline-block;
                position: absolute;
                top: 12px;
                right: 20px;
                font-size: 19px;
                opacity: 0;
                transition: $transition;
                color: $white-color;
            }
        }
    }
}
.blog-area-three {
    padding-bottom: 170px;

    .blog-item {
        margin-left: 0;
        margin-right: 0;
    }
}
/*-- End Blog Details --*/
/*----- End Blog Details Page -----*/

/*----- Practice Page -----*/
/*-- Practice --*/
.practice-area-three {
    padding-bottom: 170px;
}
/*-- End Practice --*/
/*----- End Practice Page -----*/

/*----- Practice Details Page -----*/
/*-- Practice --*/
.practice-details-area {
    padding-bottom: 170px;
    position: relative;
}
.practice-details-item {
    .practice-details-content {
        margin-bottom: 60px;

        .section-title {
            margin-bottom: 30px;
            text-align: start;

        }
        p {
            margin-bottom: 20px;
            color: #4a4a4a;
        }
        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 20px;

            li {
                list-style-type: none;
                display: block;
                color: #4a4a4a;
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
                span {
                    display: inline-block;
                    font-weight: 600;
                }
            }
        }
    }
    .blog-details-category {
        margin-bottom: 50px;
        padding: 0;
        box-shadow: none;
    }
    .practice-details-contact {
        margin-bottom: 50px;

        h3 {
            font: {
                weight: 600;
                size: 22px;
                family: $heading-font;
            }
            margin-bottom: 20px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: block;
                color: #858585;
                font-size: 15px;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    color: #858585;

                    &:hover {
                        color: $black-color;
                    }
                }
                i {
                    color: $gold-color;
                    display: inline-block;
                    font-size: 20px;
                    margin-right: 5px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
    .practice-details-brochures {
        h3 {
            font: {
                weight: 600;
                size: 22px;
                family: $heading-font;
            }
            margin-bottom: 20px;
        }
        p {
            color: #4a4a4a;
            margin-bottom: 20px;
        }
        .cmn-btn {
            i {
                display: inline-block;
                font-size: 22px;
                margin-right: 10px;
                position: relative;
                top: 1px;
            }
        }
    }
}
/*-- End Practice --*/
/*----- End Practice Details Page -----*/

/*----- Appointment Page -----*/
/*-- Team --*/
.team-area-three {
    padding-bottom: 70px;
}
.contact-form-three {
    padding-bottom: 200px;

    .form-group {
        .form-control {
            color: $black-color;
        }
    }
}
/*-- End Team --*/
/*----- End Appointment Page -----*/

/*----- Testimonial Page -----*/
/*-- Testimonial --*/
.testimonial-area {
    padding-bottom: 200px;
    
    .testimonial-more {
        text-align: center;
        margin-top: 20px;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            bottom: 22px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $gold-color;
        }
        &:after {
            position: absolute;
            content: '';
            bottom: 22px;
            left: 0;
            right: 0;
            width: 180px;
            height: 2px;
            margin-left: auto;
            margin-right: auto;
            background-color: $white-color;
        }
    }
}
.testimonial-item {
    color: $white-color;
    
    .testimonial-wrap {
        position: relative;
        margin-bottom: 60px;

        &:hover {
            p {
                background-color: $gold-color;

                &:before {
                    border-top: 45px solid $gold-color;
                }
            }
        }
        img {
            border-radius: 50%;
            position: absolute;
            left: 25px;
            bottom: -25px;
            max-width: 85px;
        }
        p {
            margin-bottom: 50px;
            background-color: $black-color;
            padding: 35px;
            border-radius: 10px;
            position: relative;
            transition: $transition;

            &:before {
                position: absolute;
                content: "";
                bottom: -35px;
                left: 28px;
                border-left: 40px solid transparent;
                border-top: 45px solid $black-color;
                border-right: 40px solid transparent;
                transition: $transition;
            }
        }
        .testimonial-right {
            padding-left: 125px;
            position: relative;
            top: 6px;

            h3 {
                font: {
                    weight: 600;
                    size: 22px;
                    family: $heading-font;
                }
                margin-bottom: 8px;
            }
            span {
                display: block;
                color: $gold-color;
                font-size: 14px;
            }
        }
    }
}
/*-- End Testimonial --*/
/*----- End Testimonial Page -----*/

/*----- FAQ Page -----*/
/*-- Faq --*/
.faq-area {
    padding-bottom: 170px;
}
.faq-head {
    h2 {
        margin-bottom: 35px;
        font-weight: 600;
        font-size: 25px;
    }
}
.faq-wrap {
    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 30px;
    }
}
.accordion { 
    padding-left: 0;
    margin: 0;
    padding: 0;

    p {
        font-size: $all-size;
        display: none;
        padding: 20px 45px 15px 20px;
        margin-bottom: 0;
        color: $black-color;
    }
    a {
        color: #232323;
        font-size: 17px;
        width: 100%;
        display: block;
        cursor: pointer;
        font-weight: 600;
        padding: 15px 0 15px 18px;
        border: 1px solid #232323;

        &:hover {
            color: $black-color;
        }
        &:after {
            position: absolute;
            right: 20px;
            content: "+";
            top: 10px;
            color: #232323;
            font-size: 25px;
            font-weight: 700;
        }
    }
    li {
        position: relative;
        list-style-type: none;
        margin-bottom: 30px;

        &:first-child {
            border-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.accordion {
    li {
        a.active {
            color: $white-color;
            background-color: $gold-color;
            border: 1px solid $gold-color;
        }
        a.active:after {
            content: "-";
            font-size: 25px;
            color: $white-color;
        }
    }
}
/*-- End Faq --*/
/*----- End Faq Page -----*/

/*----- Error Page -----*/
/*-- 404 --*/
.error-item {
    height: 800px;
    text-align: center;
    margin-top: -30px;

    h1 {
        font-size: 130px;
        font-weight: 700;
        margin-bottom: 8px;
    }
    p {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 35px;
        color: #646464;
    }
    span {
        display: block;
        color: #646464;
    }
    a {
        display: inline-block;
        color: $white-color;
        background-color: $gold-color;
        padding: 20px 45px;
        margin-top: 70px;
        font-size: 18px;

        &:hover {
            background-color: $black-color;
        }
    }
}
/*-- End 404 --*/
/*----- End Error Page -----*/

/*----- Privacy Policy Page -----*/
/*-- Privacy --*/
.privacy-area {
    padding-bottom: 150px;
}
.privacy-item {
    margin-bottom: 50px;

    h2 {
        font-size: 26px;
        margin-bottom: 15px;
        font-weight: 600;
    }
    p {
        margin-bottom: 0;
        color: #646464;
    }
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
            display: block;
            margin-bottom: 18px;
            color: #646464;

            i {
                display: inline-block;
                font-size: 20px;
                position: relative;
                bottom: -2px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
/*-- End Privacy --*/
/*----- End Privacy Policy Page -----*/

/*----- Contact Page -----*/
/*-- Location --*/
.contact-location-item {
    margin-bottom: 0;
}
/*-- End Location --*/

/*-- Contact --*/
.page-title-area-two {
    height: 550px;

    .page-title-text {
        margin-top: 15px;
    }
}
.contact-form-four {
    background-image: url('assets/img/contact-bg.png');

    .location-item {
        border: 1px solid $gold-color;
        padding: {
            top: 15px;
            bottom: 15px;
            right: 15px;
        };
        h3 {
            font-size: 22px;
            margin-bottom: 12px;
        }
        ul {
            li {
                font-size: 14px;

                a {
                    &:hover {
                        color: $gold-color;
                    }
                }
            }
        }
        .location-icon {
            i {
                width: 50px;
                height: 50px;
                line-height: 50px;
                border: 1px solid $gold-color;
                border-radius: 50%;
                text-align: center;
                font-size: 20px;
                left: 20px;
                top: 35px;
            }
        }
    }
    .location-bg {
        &:before {
            background-color: $black-color;
        }
        &:after {
            display: none;
        }
    }
    .form-group {
        .form-control {
            color: $black-color;

            &:focus {
                border: 1px solid $black-color;
            }
        }
        .nice-select.open .list {
            background-color: $white-color !important;
            border: 1px solid #b69d74;
        }
        .nice-select .option {
            color: $black-color;
        }
    }
}
/*-- End Contact --*/

/*-- Map --*/
.map-area {
    iframe {
        width: 100%;
        height: 500px;
        margin-bottom: -8px;
        border: none;
    }
}
/*-- End Map --*/
/*----- End Contact Page -----*/

/*----- Preloader -----*/
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: $gold-color;
}
.sk-folding-cube {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: relative;
    transform: rotateZ(45deg);

    .sk-cube {
        float: left;
        width: 50%;
        height: 50%;
        position: relative;
        transform: scale(1.1); 

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $white-color;
            animation: sk-foldCubeAngle 2.4s infinite linear both;
            transform-origin: 100% 100%;
        }
    }
    .sk-cube2 {
        transform: scale(1.1) rotateZ(90deg);
    }
    .sk-cube3 {
        transform: scale(1.1) rotateZ(180deg);
    }
    .sk-cube4 {
        transform: scale(1.1) rotateZ(270deg);
    }
    .sk-cube2 {
        &:before {
            animation-delay: 0.3s;
        }
    }
    .sk-cube3 {
        &:before {
            animation-delay: 0.6s; 
        }
    }
    .sk-cube4 {
        &:before {
            animation-delay: 0.9s;
        }
    }
}
@-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0; 
    } 
    25%, 75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1; 
    } 
    90%, 100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0; 
    } 
}
@keyframes sk-foldCubeAngle {
    0%, 10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0; 
    } 
    25%, 75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1; 
    } 
    90%, 100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0; 
    }
}
/*----- End Preloader -----*/

/*----- Back To Top -----*/
#toTop {
    position: fixed;
    bottom: 30px;
    right: 0;
    cursor: pointer;
    display: none;
    z-index: 10;
}
.back-to-top-btn {
    i {
        background-color: $gold-color;
        color: $white-color;
        height: 50px;
        width: 50px;
        line-height: 50px;
        display: inline-block;
        text-align: center;
        font-size: 26px;
        border-radius: 50%;
        transition: 0.5s all ease;
        margin-right: 28px;
        box-shadow: 0px 0px 14px 0px $gold-color;

        &:hover {
            background-color: $black-color;
        }
    }
}
/*----- End Back To Top -----*/


/*----- Buy Now Btn -----*/
.buy-now-btn {
    img {
        top: 50%;
        left: 20px;
        width: 15px;
        position: absolute;
        transform: translateY(-50%);
    }
    right: 20px;
    z-index: 99;
    top: 50%;
    position: fixed;
    transform: translateY(-50%);
    border-radius: 30px;
    display: inline-block;
    color: $white-color;
    background-color: #82b440;
    padding: 10px 20px 10px 42px;
    font-size: 13px;
    font-weight: 600;
    &:hover {
        color: $white-color;
        background-color: #94be5d;
    }
}
.logo-img{
    height: 45px;
    cursor: pointer;
}
.m-t-15 {
    margin-top: 15px !important;
}
li {
    text-align: justify !important;
}
.bold {
    font-weight: bold !important;
}
.p-t-6 {
    padding-top: 6rem;
    position: relative;
}